import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { Modal, ModalFuncProps, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { TSubscriptionType } from '@app/types/subscriptionType';
import { CheckBox } from '@app/components/header/components/searchDropdown/searchOverlay/SearchFilter/SearchFilter.styles';
import RecommendationScoping from '../recommendation-scoping/components/RecommendationScoping';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 25%;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const CreateButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

export const FormSubtitle = styled.span<{ fullWidth?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: #6d6d6d;
  width: ${(props) => (props.fullWidth ? '100%' : '50vw')};
`;

export type TSubscriptionForm = {
  name: string;
  recommendationScopingEnabled: boolean;
  recommendationsFilter?: string;
};

interface IManageSubscriptionFormProps extends ModalFuncProps {
  isEdit?: boolean;
  isLoading?: boolean;
  subscription?: TSubscriptionType;
  setRecommendationsFilter?: (filter: string) => void;
}

export default function ManageSubscriptionForm({
  isEdit,
  isLoading,
  subscription,
  setRecommendationsFilter,
  ...modalProps
}: IManageSubscriptionFormProps) {
  const [localRecommendationsFilter, setLocalRecommendationsFilter] = useState<string | undefined>(undefined);

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<TSubscriptionForm>({
    mode: 'onBlur',
  });

  const recommendationScopingEnabled = watch('recommendationScopingEnabled');

  const onFilterChange = (filter: string) => {
    const filterString = filter.split('&')?.find((f) => f.includes('$filter')) || '';
    setRecommendationsFilter?.(filterString);
    setLocalRecommendationsFilter(filterString);
  };

  useEffect(() => {
    if (modalProps.open && !!subscription) {
      reset({
        name: subscription.name,
        recommendationScopingEnabled: subscription.recommendationScopingEnabled,
      });

      setLocalRecommendationsFilter(subscription.recommendationsFilter || undefined);
    } else if (modalProps.open && !subscription) {
      reset({
        name: undefined,
        recommendationScopingEnabled: undefined,
      });

      setLocalRecommendationsFilter(undefined);
    }
  }, [modalProps.open, subscription, reset]);

  const onSubmit: SubmitHandler<TSubscriptionForm> = (data) => {
    const updatedData = {
      ...data,
      recommendationsFilter: localRecommendationsFilter,
    };

    modalProps.onOk?.(updatedData);
  };

  const handleCancel = () => {
    modalProps.onCancel?.();
    reset();
  };

  return (
    <Modal
      style={{
        minWidth: '1000px',
      }}
      {...modalProps}
      destroyOnClose={true}
      closable={false}
      footer={null}
    >
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <HeaderContainer>
          <FormTitle>{`${isEdit ? 'Edit' : 'Create New'} Subscription`}</FormTitle>
          <span>
            {isEdit
              ? 'Define the scope of recommendations available for this subscription.'
              : 'Define the subscription type and scope of recommendations.'}
          </span>
        </HeaderContainer>
        <Container>
          <HorizontalAlignedContainer>
            <FieldLabel>Subscription Name:</FieldLabel>
            <InputContainer>
              <FieldInput hasError={!!errors.name} {...register('name', { required: true })} />
              {!!errors.name && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>

          <HorizontalAlignedContainer>
            <FieldLabel>Recommendation Scoping:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="recommendationScopingEnabled"
                render={({ field }) => (
                  <CheckBox {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
                )}
              />
            </InputContainer>
          </HorizontalAlignedContainer>

          {recommendationScopingEnabled && (
            <RecommendationScoping onFilterChange={onFilterChange} initialFilter={localRecommendationsFilter} />
          )}

          <HorizontalAlignedContainer
            style={{
              gap: '10px',
              justifyContent: 'center',
            }}
          >
            {isLoading ? (
              <Spin />
            ) : (
              <>
                <CancelButton type="button" onClick={handleCancel}>
                  Cancel
                </CancelButton>
                <CreateButton>{isEdit ? 'Update' : 'Create'}</CreateButton>
              </>
            )}
          </HorizontalAlignedContainer>
        </Container>
      </FormContainer>
    </Modal>
  );
}
