import React from 'react';
import {
  HomeOutlined,
  SettingOutlined,
  UserAddOutlined,
  BarChartOutlined,
  FileTextOutlined,
  FileOutlined,
  BulbOutlined,
  UsergroupAddOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { BsDatabaseFill } from 'react-icons/bs';
import { HiOutlineBell } from 'react-icons/hi';
import { MdOutlineSecurity } from 'react-icons/md';
import { TfiBook } from 'react-icons/tfi';
import { GrCompliance } from 'react-icons/gr';

import { useAppSelector } from '@app/hooks/reduxHooks';
import { FaMoneyCheck } from 'react-icons/fa';

const notDeletedStatusFilter = [
  {
    column: 'status',
    enumKey: 'UserStatus',
    value: JSON.stringify([1, 2, 3]),
    type: 'enum',
    title: 'Status',
  },
];

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  allow?: boolean;
}

function getSideBarItems(regulations: { id: number; name: string }[]): SidebarNavigationItem[] {
  return [
    {
      title: 'common.homepage',
      key: 'HomePage',
      // TODO use path variable
      url: '/dashboard',
      icon: <HomeOutlined />,
    },
    {
      title: 'common.alert',
      key: 'AlertsHistory',
      icon: <HiOutlineBell />,
      children: [
        {
          title: 'common.activeAlerts',
          key: 'ActiveAlerts',
          url: '/active-alerts',
        },
        {
          title: 'common.alertsHistory',
          key: 'AlertsHistory',
          url: '/alerts-history',
        },
        {
          title: 'common.manageAlerts',
          key: 'Alerts',
          url: '/manage-alerts',
        },
      ],
    },
    {
      title: 'common.manageAccounts',
      key: 'ManageAccounts',
      // TODO use path variable
      url: `/manage-accounts?$filter=status eq 1`,
      icon: <UserAddOutlined />,
    },
    {
      title: 'common.manageVendors',
      key: 'ManageVendors',
      // TODO use path variable
      url: `/manage-vendors?$filter=status eq 1`,
      icon: <UsergroupAddOutlined />,
    },
    {
      title: 'common.manageRecommendations',
      key: 'ManageRecommendations',
      url: '/manage-recommendations',
      icon: <MdOutlineSecurity />,
    },
    {
      title: 'common.manageStories',
      key: 'ManageStories',
      url: '/manage-stories',
      icon: <TfiBook />,
    },
    {
      title: 'common.manageInsights',
      key: 'ManageInsights',
      url: '/manage-insights',
      icon: <BarChartOutlined />,
    },
    {
      title: 'common.dataConnectors',
      key: 'DataConnectors',
      url: '/data-connectors',
      icon: <BsDatabaseFill />,
    },
    {
      title: 'common.manageReports',
      key: 'ManageReports',
      url: '/manage-reports',
      icon: <FileTextOutlined />,
    },
    {
      title: 'common.manageRegulations',
      key: 'ManageRegulations',
      url: '/manage-regulations',
      icon: <GrCompliance />,
    },
    {
      title: 'common.recommendations',
      key: 'Recommendations',
      icon: <MdOutlineSecurity />,
      children: [
        {
          title: 'common.recommendationsRepository',
          key: 'Recommendations',
          url: '/recommendations',
        },
        {
          title: 'common.history',
          key: 'RecommendationHistory',
          url: '/recommendation-history',
        },
        // {
        //   title: 'common.active',
        //   key: 'ActiveRecommendations',
        //   url: '/active-recommendations',
        // },
      ],
    },
    {
      title: 'common.stories',
      key: 'Stories',
      url: '/stories',
      icon: <TfiBook />,
    },
    {
      title: 'common.compliance',
      key: 'RegulationsCompliance',
      url: '/regulations-compliance',
      icon: <GrCompliance />,
      children: regulations
        ? regulations.map((regulation) => ({
            title: regulation.name,
            key: `RegulationInformation/${regulation.id}`,
            url: `/regulation-information/${regulation.id}`,
            allow: true,
          }))
        : [],
    },
    {
      title: 'common.insights',
      key: 'Insights',
      url: '/insights',
      icon: <BulbOutlined />,
    },
    {
      title: 'common.reports',
      key: 'Reports',
      url: '/reports',
      icon: <FileOutlined />,
    },
    {
      title: 'Settings',
      key: 'GeneralSettings',
      icon: <SettingOutlined />,
      children: [
        {
          title: 'General',
          key: 'GeneralSettings',
          url: 'settings/general',
        },
        {
          title: 'Manage Users',
          key: 'ManageUsers',
          url: `settings/manage-users?$added=${JSON.stringify(notDeletedStatusFilter)}`,
        },
        {
          title: 'Manage Groups',
          key: 'ManageGroups',
          url: `settings/manage-groups`,
        },
      ],
    },
    {
      title: 'common.manageSettings',
      key: 'ManageSettings',
      url: '/manage-settings',
      icon: <SettingOutlined />,
    },
    {
      title: 'Messaging',
      key: 'Messaging',
      url: '/messaging',
      icon: <MailOutlined />,
    },
    {
      title: 'common.subscriptionTypes',
      key: 'SubscriptionTypes',
      url: '/subscription-types',
      icon: <FaMoneyCheck />,
    },
  ];
}

export const useSideBarNavigation = (): SidebarNavigationItem[] => {
  const allowedScreens = useAppSelector((state) => state.app.allowedScreensAndActions?.screens) as string[];
  const regulations = useAppSelector((state) => state.user.regulations);
  const sidebarItems = getSideBarItems(regulations);
  const filteredSidebarItems = sidebarItems
    .filter((item) => allowedScreens?.includes(item.key))
    .map((item) => ({ ...item, children: item.children?.filter((child) => allowedScreens.includes(child.key)) }));
  return filteredSidebarItems;
};
